<template>
  <cit-data-table
    :headers="headers"
    resource-path="product-extra-options/categories"
  >

    <template v-slot:actions>
      <create-form/>
    </template>

    <template v-slot:item.actions="{item}">
      <edit-form :item="item"/>
      <action-delete :action-url="`product-extra-options/categories/${item.id}`"/>
      <view-button :to="{name:'pm-extra-options-category-detail', params:{id:item.id}}" target="_self"/>
    </template>

  </cit-data-table>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import CreateForm from './Create.vue'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import EditForm from './Edit.vue'
import ViewButton from '@/components/general-form/ViewButton'

export default {
  components: {
    ActionDelete,
    CitDataTable,
    CreateForm,
    EditForm,
    ViewButton
  },
  data () {
    return {
      headers: [
        {
          text: 'Denumire interna',
          value: 'internal_name',
          filterType: 'text'
        },
        {
          text: 'Denumire publica',
          value: 'public_name',
        },
      ]
    }
  }
}
</script>
